import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
// import { ModalPageModule } from './pages/modal/modal.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorProvider } from './services/interceptors/interceptor';
import { IonicStorageModule } from '@ionic/storage';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ModalPageModule } from './pages/modal/modal.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'; 
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#01baef",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-scale-multiple",
  "blur": 1,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#01baef",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "square-jelly-box",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#01baef",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        ModalPageModule,
        MatDatepickerModule,
        NgxUiLoaderHttpModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
    ],
    providers: [
        SplashScreen,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorProvider,
            multi: true
        },
        InAppBrowser // Add this line
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule {}
