import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonUtils } from 'src/app/services/common-utils/common-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  // Variable start
  main_url = environment.apiUrl;
  file_url = environment.fileUrl;

  private getOrderListSubscribe: Subscription;
  private getCardHistorySubscribe: Subscription;
  private getWalletHistorySubscribe: Subscription;
  

  get_identifier;
  get_item;
  get_array;
  heder_title;
  model: any = {};
  orderItem: any = [];
  orderData = [];
  totalPrice: any;
  service_fee : any;
  // free_service_fee = 0;
  listLoader = false;
  cardhistoryData: any = [];
  wallethistoryData: any = [];
  PDF_url: any;
  wallet_discount_amount: any;
  roll_id: any;
  wallet_discount_percentage: any;
  handle_care: any = 0;
  constructor(
    private navParams : NavParams,
    private modalController : ModalController,
    private http : HttpClient,
    private authService:AuthService,
    private router:Router,
    private commonUtils: CommonUtils, // common functionlity come here
    private ngxLoader:NgxUiLoaderService,
  ) { }

  ngOnInit() {
    this.get_identifier =  this.navParams.get('identifier');
    this.get_item =  this.navParams.get('modalForm_item');
    this.get_array =  this.navParams.get('modalForm_array');
    if(this.get_identifier == 'order_view_modal'){
      this.getOrderList();
      this.heder_title = 'View Order';
    }else if(this.get_identifier == 'membership_modal'){
      this.heder_title = 'Delivery Card History';
      this.getcardHistory();
    }else if(this.get_identifier == 'wallet_modal'){
      this.heder_title = 'Wallet Transaction History';
      this.getwalletHistory();
    }
  }

  // for License Suspension start
  // close modal
  closeModal() {
    this.modalController.dismiss();
  }

  // Date format change start
  changeDateFormat(_identifier, _date){
  
  }
  // Date format change end
  
  // getOrderList start
  getOrderList(){
    // console.log("this.get_item....>>",this.get_array);
    this.ngxLoader.start();
    this.listLoader = true;
    this.getOrderListSubscribe = this.http.get('getorderlist').subscribe(
      (res:any) => {
        if(res.return_status > 0){
          this.totalPrice = 0;
          this.ngxLoader.stop();
          this.listLoader = false;
          this.orderItem = res.return_data;
          for (let i = 0; i < this.orderItem.length; i++) {
            if (this.orderItem[i].order_code == this.get_item && this.orderItem[i].delivery_status != 5) {
              this.orderData.push(this.orderItem[i]);
            }            
          }
          let sum = 0;
          for (let i = 0; i < this.orderData.length; i++) {
            console.log("this.orderData......", this.orderData);
            this.roll_id = this.orderData[0].roll_id;
            this.service_fee =  this.orderData[0].service_fee;
            this.wallet_discount_amount = this.orderData[0].wallet_discount_amount;
            this.wallet_discount_percentage = this.orderData[0].wallet_discount_percentage;
            console.log('handle_care..',this.handle_care);
            sum = sum + parseFloat(this.orderData[i].getproduct.handle_care);
            this.totalPrice = this.totalPrice + this.orderData[i].total_price;
            console.log('orderData', this.orderData,this.handle_care);
            
          }
          this.handle_care = sum.toFixed(2);
        }
        else {
          this.ngxLoader.stop();
          this.listLoader = false;
          this.commonUtils.presentToast('error', res.return_message);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        this.listLoader = false;
        this.commonUtils.presentToast('error', err.message); 
      }
    );
  }
  // getOrderList end

  getcardHistory(){
    this.ngxLoader.start();
    this.listLoader = true;
    this.getCardHistorySubscribe = this.http.get('delivery-card-history').subscribe(
      (res:any) => {
        if(res.return_status > 0){
          this.ngxLoader.stop();
          this.listLoader = false;
          this.cardhistoryData = res.return_data;
          console.log("data....",this.cardhistoryData)
        }
        else {
          this.ngxLoader.stop();
          this.listLoader = false;
          this.commonUtils.presentToast('error', res.return_message);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        this.listLoader = false;
        this.commonUtils.presentToast('error', err.message); 
      }
    );
  }
  getwalletHistory(){
    this.ngxLoader.start();
    this.listLoader = true;
    this.getWalletHistorySubscribe = this.http.get('membership-wallet-history').subscribe(
      (res:any) => {
        if(res.return_status > 0){
          this.ngxLoader.stop();
          this.listLoader = false;
          this.wallethistoryData = res.return_data;
        }
        else {
          this.ngxLoader.stop();
          this.listLoader = false;
          this.commonUtils.presentToast('error', res.return_message);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        this.listLoader = false;
        this.commonUtils.presentToast('error', err.message); 
      }
    );
  }

  pdfDownload(type){
    this.ngxLoader.start();
    var data;
    if(type == 'wallet'){
      data = 'renual-history';
    }else{
      data = 'card-history';
    }
    this.listLoader = true;
    this.getWalletHistorySubscribe = this.http.get(data).subscribe(
      (res:any) => {
        if(res.return_status > 0){
          this.ngxLoader.stop();
          this.listLoader = false;
          this.PDF_url =this.file_url+res.pdf_path+res.filename;
          window.open(this.PDF_url, '_blank');
          console.log("this.PDF_url..",this.PDF_url);
          this.fileDelete(res.filename);
        }
        else {
          this.ngxLoader.stop();
          this.listLoader = false;
          this.commonUtils.presentToast('error', res.return_message);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        this.listLoader = false;
        this.commonUtils.presentToast('error', err.message); 
      }
    );
  }
  fileDelete(data){
    this.listLoader = true;
    var fd  = new FormData();
    fd.append("filename",data);
    this.getWalletHistorySubscribe = this.http.post('unlink-history',fd).subscribe(
      (res:any) => {
      },
      (err) => {
      }
    );
  }
  ngOnDestroy(){
    if(this.getOrderListSubscribe !== undefined){
      this.getOrderListSubscribe.unsubscribe();
    }
    if(this.getCardHistorySubscribe !== undefined){
      this.getCardHistorySubscribe.unsubscribe();
    }
    if(this.getWalletHistorySubscribe !== undefined){
      this.getWalletHistorySubscribe.unsubscribe();
    }
  }
}